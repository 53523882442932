import React from "react"

const Sun = props => (
  <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
    <path
      d="M477.45 256c0-21.75 39.41-48.387 34.05-68.45-5.55-20.757-53.156-24.1-63.672-42.277-10.668-18.433 10.125-61.304-4.836-76.265-14.96-14.961-57.832 5.828-76.27-4.836C348.552 53.656 345.208 6.05 324.45.5 304.387-4.86 277.75 34.55 256 34.55S207.613-4.86 187.55.5c-20.757 5.55-24.1 53.156-42.277 63.672C126.84 74.84 83.97 54.047 69.008 69.008c-14.961 14.96 5.828 57.832-4.836 76.27C53.656 163.448 6.05 166.792.5 187.55-4.86 207.613 34.55 234.25 34.55 256S-4.86 304.387.5 324.45c5.55 20.757 53.156 24.1 63.672 42.277 10.668 18.433-10.125 61.304 4.836 76.265 14.96 14.961 57.832-5.828 76.27 4.836 18.171 10.516 21.515 58.121 42.273 63.672 20.062 5.36 46.699-34.05 68.449-34.05s48.387 39.41 68.45 34.05c20.757-5.55 24.1-53.156 42.277-63.672 18.433-10.668 61.304 10.125 76.265-4.836 14.961-14.96-5.828-57.832 4.836-76.27 10.516-18.171 58.121-21.515 63.672-42.273 5.36-20.062-34.05-46.699-34.05-68.449zm0 0"
      fill="#ffee8c"
    />
    <path
      d="M426.957 256c0 86.348-64.02 157.746-147.191 169.313a170.874 170.874 0 01-23.762 1.64c-94.418 0-170.969-76.539-170.969-170.953 0-94.418 76.55-170.957 170.969-170.957 8.066 0 15.996.555 23.762 1.64C362.937 98.25 426.957 169.649 426.957 256zm0 0"
      fill="#f28f44"
    />
    <path
      d="M426.957 256c0 86.348-64.02 157.746-147.191 169.313C196.59 413.745 132.57 342.347 132.57 256c0-86.352 64.02-157.75 147.196-169.316C362.937 98.25 426.957 169.648 426.957 256zm0 0"
      fill="#ffd073"
    />
    <g fill="#cc9236">
      <path d="M155.875 229.488a7.802 7.802 0 01-5.54-2.297 7.832 7.832 0 010-11.082c17.532-17.53 46.056-17.53 63.583 0a7.832 7.832 0 010 11.082 7.84 7.84 0 01-11.082 0c-11.418-11.421-29.996-11.421-41.418 0a7.811 7.811 0 01-5.543 2.297zm0 0M356.125 229.488a7.811 7.811 0 01-5.543-2.297c-11.418-11.421-30-11.418-41.418 0a7.84 7.84 0 01-11.082 0 7.832 7.832 0 010-11.082c17.527-17.53 46.055-17.53 63.586 0a7.84 7.84 0 01-5.543 13.38zm0 0M256 310.582c-17.016 0-33.016-6.629-45.047-18.66a7.84 7.84 0 010-11.082 7.832 7.832 0 0111.082 0c9.074 9.07 21.133 14.066 33.965 14.066s24.89-4.996 33.965-14.066a7.832 7.832 0 0111.082 0 7.84 7.84 0 010 11.082c-12.031 12.031-28.031 18.66-45.047 18.66zm0 0" />
    </g>
  </svg>
)

export default Sun
